<template>
  <div class="tour-overview" v-if="loaded">
    <div class="tour-overview-header">
      <div class="breadcrumbs">
        <div>
          <div class="home-icon">
            <router-link
              :to="{
                name: 'events',
              }"
            >
              <Home />
            </router-link>
            <p class="divider">/</p>
          </div>
          <template v-if="!oneOffEvent">
            <router-link
              class="tour-name"
              :to="{
                name: 'tournamentoverview-standings',
                params: { tourId: eventData.tour.id },
              }"
            >
              <p>Tour overview</p>
            </router-link>
            <p class="divider">/</p>
          </template>
          <router-link :to="{ name: 'public-event-results' }">
            <p>Event overview</p>
          </router-link>
          <template v-if="$route.name == 'public-event-checkout'">
            <p class="divider">/</p>
            <p class="tail">Checkout</p>
          </template>
          <template v-if="$route.name == 'public-event-group-registration'">
            <p class="divider">/</p>
            <p class="tail">Registration</p>
          </template>
        </div>
      </div>
      <div class="left">
        <h2>{{ eventData.name }}</h2>
        <p class="start-date-mobile">
          {{ formattedEventDate
          }}<template v-if="eventData.city">
            <span class="mx-2">•</span>{{ eventData.city.name }},
            {{ eventData.city.country }}</template
          >
        </p>
        <p class="part-of-tour" v-if="!oneOffEvent">
          Part of {{ eventData.tour.name }} (event {{ eventNumberInTour }} of
          {{ eventData.tour.events.length }})
        </p>
        <div class="event-user-actions" v-if="eventStatus == 'CLOSED'">
          <template v-if="userRegistrationStatus.registered == true">
            <template
              v-if="
                userRegistrationStatus.status == 'ACCEPTED' &&
                eventData.status == 'CLOSED'
              "
            >
              <On color="ocean" />
              <p>
                <strong>You're confirmed</strong>
                <span
                  class="unregister"
                  @click="$refs.unregisterconfirmation.open()"
                  >Unregister</span
                >
              </p>
            </template>
            <template
              v-else-if="
                userRegistrationStatus.status == 'PENDING' &&
                eventData.status == 'CLOSED'
              "
            >
              <WaitingList />
              <p>
                <strong>You're on the waiting list</strong>
                <span
                  class="unregister"
                  @click="$refs.unregisterconfirmation.open()"
                  >Unregister</span
                >
              </p>
            </template>
          </template>
        </div>
      </div>
      <div class="right">
        <div class="event-date">
          <span class="icon-wrapper">
            <CalendarIcon />
          </span>
          {{ formattedEventDate }}
        </div>
        <div class="next-event" v-if="eventData.city">
          <span class="icon-wrapper">
            <Location />
          </span>
          <p>{{ eventData.city.name }}, {{ eventData.city.country }}</p>
        </div>
        <div class="tags">
          <span class="card-label sunset" v-if="eventData.tour.type">{{
            eventData.tour.type.name
          }}</span>
          <span class="card-label ocean" v-if="eventData.pdgaType">{{
            eventData.pdgaType.name
          }}</span>
        </div>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>

    <sweet-modal
      :enable-mobile-fullscreen="false"
      blocking
      ref="unregisterconfirmation"
      hide-close-button
    >
      <h3>Cancel your spot</h3>
      <p>Are you sure you want to remove yourself from this event?</p>

      <div class="buttons">
        <StandardSolidButton
          title="Not now"
          :fluid="false"
          @click="$refs.unregisterconfirmation.close()"
        />
        <StandardBorderedButton
          class="forwards"
          title="Remove me"
          :fluid="false"
          @click="unregister()"
        />
      </div>
    </sweet-modal>
  </div>
</template>

<script>
import moment from "moment";
import Location from "@/assets/icons/Location";
import CalendarIcon from "@/assets/icons/CalendarIcon";
import WaitingList from "@/assets/icons/WaitingList";
import On from "@/assets/icons/On";
import Home from "@/assets/icons/Home";
import StandardSolidButton from "@/components/UIElements/StandardSolidButton";
import StandardBorderedButton from "@/components/UIElements/StandardBorderedButton";

export default {
  name: "PublicEventRegistration",
  components: {
    CalendarIcon,
    StandardSolidButton,
    StandardBorderedButton,
    Location,
    On,
    WaitingList,
    Home,
  },
  data() {
    return {
      loaded: false,
      eventData: null,
    };
  },
  computed: {
    route() {
      return this.$route;
    },
    eventStatus() {
      if (this.eventData.status == "COMPLETED") return "COMPLETED";

      let eventStarted = false;
      this.eventData.rounds.forEach((round) => {
        round.pools.forEach((pool) => {
          if (pool.status == "OPEN" || pool.status == "COMPLETED") {
            eventStarted = true;
          }
        });
      });

      if (eventStarted) return "ONGOING";

      return "CLOSED";
    },
    userHasValidTourPass() {
      const userId = this.$store.getters.userInfo.sessionInfo.userId;
      const tourPasses = this.$store.getters.tourPasses;
      const tourId = this.eventData.tour.id;

      let userHasValidTourPass = false;

      tourPasses.forEach((tourPass) => {
        if (
          tourPass.tour.id == tourId &&
          tourPass.payment.isRefunded == false
        ) {
          userHasValidTourPass = true;
        }
      });

      return userHasValidTourPass;
    },
    userIsAPlayer() {
      const players = this.eventData.players;
      const userId = this.$store.getters.userInfo.sessionInfo.userId;
      let userIsAPlayer = false;

      players.forEach((player) => {
        if (player.userId == userId) {
          userIsAPlayer = true;
        }
      });

      return userIsAPlayer;
    },
    userRegistrationStatus() {
      const userRegistrations = this.$store.getters.registrations;
      const eventId = this.eventData.id;
      const players = this.eventData.players;
      const userId = this.$store.getters.userInfo.sessionInfo.userId;

      let userRegistrationStatus = {
        registered: false,
        registrationId: null,
        status: null,
        playerId: null,
      };

      if (this.$store.getters.isUserLoggedIn) {
        userRegistrations.forEach((registration) => {
          if (registration.event.id == eventId) {
            userRegistrationStatus.status = registration.status;
            userRegistrationStatus.registrationId = registration.id;

            if (
              registration.status == "PENDING" ||
              registration.status == "ACCEPTED"
            ) {
              userRegistrationStatus.registered = true;
            }
          }
        });

        players.forEach((player) => {
          if (player.userId == userId) {
            userRegistrationStatus.playerId = player.id;
          }
        });
      }

      return userRegistrationStatus;
    },
    formattedEventDate() {
      const startDate = moment(this.eventData.startDate);
      const endDate = moment(this.eventData.endDate);

      if (startDate.isSame(endDate, "day"))
        return startDate.format("ddd D MMM");
      if (startDate.isSame(endDate, "month"))
        return `${startDate.format("D")}-${endDate.format("D MMM")}`;

      return `${startDate.format("D MMM")} - ${endDate.format("D MMM")}`;
    },
    eventNumberInTour() {
      let counter = 0;
      this.eventData.tour.events.forEach((event, index) => {
        if (event.id == this.eventData.id) counter = index + 1;
      });

      return counter;
    },
    oneOffEvent() {
      return this.eventData.tour.events.length > 1 ? false : true;
    },
  },
  methods: {
    async unregister() {
      const userRegistrationId = this.userRegistrationStatus.registrationId;
      try {
        let registration = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
            mutation{
              CancelRegistration(registrationId:"${userRegistrationId}"){
                id
              }
            }
          `,
          },
        });

        this.$refs.unregisterconfirmation.close();
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Spot cancelled",
          type: "success",
        });
        this.$store.dispatch("updateUserEventInformation");
        this.loadData();
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "Error",
        });
      }
    },
    async loadData() {
      let tourInfo = await this.$axios({
        data: {
          query: `
          {
            event(eventId: "${this.$router.currentRoute.params.eventId}") {
              id
              name
              status
              startDate
              endDate
              isRegistrationOpen
              rounds{
                id
                pools{
                  id
                  status
                }
              }
              players{
                userId
                id
              }
              pdgaType {
                type
                name
              }
              city {
                id
                name
                country
              }
              tour {
                id
                name
                registrationMethod
                type {
                  name
                  type
                }
                events {
                  id
                }
              }
            }
          }
          `,
        },
      });

      this.eventData = tourInfo.data.data.event;
      this.loaded = true;
    },
  },
  beforeMount() {
    this.loadData();
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(10px);
}

.tour-overview-header {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;

  h2 {
    @include Gilroy-Bold;
    font-size: 26px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    display: block;
  }

  .breadcrumbs {
    margin: 24px 0 36px 0;
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 0 18px;
    .home-icon {
      display: flex;
      align-items: center;
      font-size: 12px;
      svg {
        margin-bottom: 4px;
      }
    }
    div {
      display: flex;
      .tour-name {
        max-width: 40%;
      }
      a {
        color: $ocean;
        &:hover {
          text-decoration: underline;
        }
      }
      p {
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin: 0;

        &.divider {
          min-width: 12px;
          text-align: center;
        }

        &.tail {
          min-width: 46px;
        }
      }
    }
  }
  .right {
    display: none;
  }
  .left {
    width: 100%;

    h2,
    p {
      padding-left: 18px;
    }
    .finished-event-info {
      display: none;
    }

    .mobile-event-user-actions {
      padding: 0 18px;
      .check-in-now {
        margin-top: 28px;
      }
      .register-now {
        margin-top: 28px;
      }
      .finished-event-info-mobile {
        margin-top: 16px;
        padding-bottom: 24px;
        border-bottom: 1px solid $sleet;
        position: relative;
        display: flex;
        align-items: center;
        p {
          margin: 0;
          font-size: 14px;
          padding-left: 0;
          margin-left: 8px;
        }
        svg {
          width: 20px;
          height: 20px;
        }
      }
      .event-is-live {
        margin-top: 16px;
        padding-bottom: 24px;
        border-bottom: 1px solid $sleet;
        position: relative;
        display: flex;
        align-items: center;
        p {
          margin: 0;
          font-size: 14px;
          padding-left: 0;
          @include Gilroy-Bold;
        }
      }
      .user-status {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        p {
          font-size: 14px;
          margin: 0;
          padding: 0;
          span {
            @include Gilroy-Regular;
            color: $twilight;
            cursor: pointer;
          }
          strong {
            @include Gilroy-Bold;
          }
        }
        svg {
          margin-right: 7px;
        }

        &.you-are-confirmed {
          margin-top: 16px;
          padding-bottom: 24px;
          border-bottom: 1px solid $sleet;
          svg {
            width: 16px;
            height: 16px;
          }
          span {
            margin-left: 8px;
          }
        }
        &.you-are-on-waitinglist {
          margin-top: 16px;
          padding-bottom: 24px;
          border-bottom: 1px solid $sleet;
          svg {
            width: 16px;
            height: 16px;
          }
        }
        &.you-are-checked-in {
          margin-top: 16px;
          padding-bottom: 24px;
          border-bottom: 1px solid $sleet;
          svg {
            width: 16px;
            height: 20px;
          }
        }
      }
    }
    .start-date-mobile {
      margin-top: 12px;
      font-size: 14px;
      margin-bottom: 0;
    }
    .part-of-tour,
    .event-user-actions {
      display: none;
    }

    .ongoing-event-info {
      display: none;
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  .mobile-banner-info {
    display: none;
  }
  .mobile-event-user-actions {
    display: none;
  }
  .tour-overview-header {
    padding: 0;

    h2 {
      font-size: 48px;
    }

    .breadcrumbs {
      padding: 50px 0 40px 0;
      border-right: 1px solid $fog;
      width: 75%;
      margin: 0;
      display: flex;
      align-items: center;

      .home-icon {
        svg {
          width: 18px;
          height: auto;
        }
      }
      div {
        a {
          color: $ocean;
        }
        p {
          font-size: 16px;
        }
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding-left: 24px;
      min-height: 100px;
      max-width: 25%;

      .icon-wrapper {
        display: flex;
        align-items: center;
        min-width: 30px;
        justify-content: center;
        margin-right: 10px;
      }

      .next-event {
        margin-top: 16px;
        display: flex;
        align-items: center;

        p {
          font-size: 18px;
          margin-bottom: 0;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .event-date {
        display: flex;
        align-items: center;
        font-size: 18px;
      }

      .tags {
        margin-top: 64px;
        display: block;

        .card-label {
          margin-right: 12px;
        }
      }
    }
    .left {
      width: 75%;
      margin-top: 0;
      border-right: 1px solid $fog;
      padding-right: 24px;
      display: flex;
      flex-direction: column;

      h2 {
        padding-left: 0;
      }
      button {
        margin-top: 32px;
      }

      .start-date-mobile {
        display: none;
      }
      .part-of-tour {
        display: block;
        font-size: 18px;
        margin-top: 8px;
        margin-bottom: 0;
        padding: 0;
      }
      .finished-event-info {
        margin-top: auto;
        display: flex;
        align-items: center;
        p {
          margin: 0;
          font-size: 18px;
        }
      }
      .ongoing-event-info {
        margin-top: auto;
        display: flex;
        align-items: center;
        p {
          margin: 0;
          font-size: 18px;
        }
      }

      .event-user-actions {
        display: flex;
        align-items: center;
        margin-top: auto;
        p {
          font-size: 16px;
          margin: 0;
          padding: 0;
        }

        .check-in-opens {
          margin-left: 48px;
          display: flex;
          align-items: center;
          p {
            strong {
              @include Gilroy-Bold;
            }
          }
        }

        .check-in-now {
          display: flex;
          align-items: center;
          button {
            margin: 0 32px 0 0;
          }
        }

        .unregister {
          cursor: pointer;
          color: $twilight;
          margin-left: 10px;
          transition: all ease 0.3s;

          &:hover {
            color: $twilightshade;
          }
        }

        strong {
          @include Gilroy-Bold;
        }
        svg {
          margin-right: 10px;
        }
      }

      button {
        margin-top: 24px;
      }
    }

    nav {
      padding: 0px;
      display: flex;
      width: 100%;
      margin: 48px 0 64px 0;

      &.rounds-left {
        padding-right: 0;
        width: 40%;
      }

      &.rounds-right {
        padding: 0;
        width: 60%;
      }
    }
  }
}
</style>

