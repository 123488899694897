var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('div',{staticClass:"tour-overview"},[_c('div',{staticClass:"tour-overview-header"},[_c('div',{staticClass:"breadcrumbs"},[_c('div',[_c('div',{staticClass:"home-icon"},[_c('router-link',{attrs:{"to":{
              name: 'events',
            }}},[_c('Home')],1),_c('p',{staticClass:"divider"},[_vm._v("/")])],1),(!_vm.oneOffEvent)?[_c('router-link',{staticClass:"tour-name",attrs:{"to":{
              name: 'tournamentoverview-standings',
              params: { tourId: _vm.eventData.tour.id },
            }}},[_c('p',[_vm._v("Tour overview")])]),_c('p',{staticClass:"divider"},[_vm._v("/")])]:_vm._e(),_c('router-link',{attrs:{"to":{ name: 'public-event-results' }}},[_c('p',[_vm._v("Event overview")])]),(_vm.$route.name == 'public-event-checkout')?[_c('p',{staticClass:"divider"},[_vm._v("/")]),_c('p',{staticClass:"tail"},[_vm._v("Checkout")])]:_vm._e(),(_vm.$route.name == 'public-event-group-registration')?[_c('p',{staticClass:"divider"},[_vm._v("/")]),_c('p',{staticClass:"tail"},[_vm._v("Registration")])]:_vm._e()],2)]),_c('div',{staticClass:"left"},[_c('h2',[_vm._v(_vm._s(_vm.eventData.name))]),_c('p',{staticClass:"start-date-mobile"},[_vm._v(" "+_vm._s(_vm.formattedEventDate)),(_vm.eventData.city)?[_c('span',{staticClass:"mx-2"},[_vm._v("•")]),_vm._v(_vm._s(_vm.eventData.city.name)+", "+_vm._s(_vm.eventData.city.country))]:_vm._e()],2),(!_vm.oneOffEvent)?_c('p',{staticClass:"part-of-tour"},[_vm._v(" Part of "+_vm._s(_vm.eventData.tour.name)+" (event "+_vm._s(_vm.eventNumberInTour)+" of "+_vm._s(_vm.eventData.tour.events.length)+") ")]):_vm._e(),(_vm.eventStatus == 'CLOSED')?_c('div',{staticClass:"event-user-actions"},[(_vm.userRegistrationStatus.registered == true)?[(
              _vm.userRegistrationStatus.status == 'ACCEPTED' &&
              _vm.eventData.status == 'CLOSED'
            )?[_c('On',{attrs:{"color":"ocean"}}),_c('p',[_c('strong',[_vm._v("You're confirmed")]),_c('span',{staticClass:"unregister",on:{"click":function($event){return _vm.$refs.unregisterconfirmation.open()}}},[_vm._v("Unregister")])])]:(
              _vm.userRegistrationStatus.status == 'PENDING' &&
              _vm.eventData.status == 'CLOSED'
            )?[_c('WaitingList'),_c('p',[_c('strong',[_vm._v("You're on the waiting list")]),_c('span',{staticClass:"unregister",on:{"click":function($event){return _vm.$refs.unregisterconfirmation.open()}}},[_vm._v("Unregister")])])]:_vm._e()]:_vm._e()],2):_vm._e()]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"event-date"},[_c('span',{staticClass:"icon-wrapper"},[_c('CalendarIcon')],1),_vm._v(" "+_vm._s(_vm.formattedEventDate)+" ")]),(_vm.eventData.city)?_c('div',{staticClass:"next-event"},[_c('span',{staticClass:"icon-wrapper"},[_c('Location')],1),_c('p',[_vm._v(_vm._s(_vm.eventData.city.name)+", "+_vm._s(_vm.eventData.city.country))])]):_vm._e(),_c('div',{staticClass:"tags"},[(_vm.eventData.tour.type)?_c('span',{staticClass:"card-label sunset"},[_vm._v(_vm._s(_vm.eventData.tour.type.name))]):_vm._e(),(_vm.eventData.pdgaType)?_c('span',{staticClass:"card-label ocean"},[_vm._v(_vm._s(_vm.eventData.pdgaType.name))]):_vm._e()])])]),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view')],1),_c('sweet-modal',{ref:"unregisterconfirmation",attrs:{"enable-mobile-fullscreen":false,"blocking":"","hide-close-button":""}},[_c('h3',[_vm._v("Cancel your spot")]),_c('p',[_vm._v("Are you sure you want to remove yourself from this event?")]),_c('div',{staticClass:"buttons"},[_c('StandardSolidButton',{attrs:{"title":"Not now","fluid":false},on:{"click":function($event){return _vm.$refs.unregisterconfirmation.close()}}}),_c('StandardBorderedButton',{staticClass:"forwards",attrs:{"title":"Remove me","fluid":false},on:{"click":function($event){return _vm.unregister()}}})],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }